
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LandingFeatureGrid extends Vue {
  @Prop({ default: [] }) landingFeatures!: any[];
  @Prop({ default: '' }) variant!: string;

  layoutType(): string | null {
    return this.landingFeatures.length === 4 ? 'four' : null;
  }

  featureGridClasses(): any[] {
    return [this.variant, this.layoutType()];
  }
}
